import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    BooleanField,
    DateField,
    EditButton
} from 'react-admin';

// own ui
import CustomFilterTextActive from '../../ui/custom-filter-text-active';
import CollectionPointPassportAdminExporter from '../../exporter/collection-point-passport-admin-exporter';

const CollectionPointPassportAdminList = (props) => (
    <List
      title="Admins de Ponto de Retirada de Passaporte" {...props}
      sort={{ field: 'id', order: 'desc' }}
      filters={ <CustomFilterTextActive /> }
      filterDefaultValues={{ active: true }}
      bulkActionButtons={ false }
      exporter={ CollectionPointPassportAdminExporter } >
        <Datagrid
          rowClick="show">
          <TextField source="id" sortable={true} />
          <TextField source="collection_point_passport_name" label="Ponto de Retirada" sortable={false} />
          <TextField source="name" label="Nome" sortable={false} />
          <TextField source="email" label="Responsável" sortable={false} />
          <BooleanField source="is_master" label="Master" sortable={false} />
          <BooleanField source="active" label="Ativo" sortable={false} />
          <DateField source="created_at" locales="pt-BR" label="Criado em" sortable={false} />
          <EditButton />
        </Datagrid>
    </List>
);

export default CollectionPointPassportAdminList;
