import React from 'react';
import {
    Show,
    TextField,
    BooleanField,
    SimpleShowLayout
} from 'react-admin';

const CollectionPointPassportAdminShow = props => (
    <Show
      title="Admin de Ponto de Retirada de Passaporte" {...props}>
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="collection_point_passport_name" label="Ponto de Retirada" sortable={false} />
        <TextField source="name" label="Nome" sortable={false} />
        <TextField source="email" label="Responsável" sortable={false} />
        <BooleanField source="is_master" label="Master" sortable={false} />
        <BooleanField source="active" label="Ativo" sortable={false} />
      </SimpleShowLayout>
    </Show>
);

export default CollectionPointPassportAdminShow;
