import React from 'react';
import {
  required,
  Edit,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  BooleanInput
} from 'react-admin';

// own ui
import EditTitle from '../../ui/edit-title';
import CustomEditToolbar from '../../ui/custom-edit-toolbar';

const validateCollectionPointPassport = [required('Selecione um ponto de retirada!')];
const validateName = [required('Digite o nome!')];
const validateEmail = [required('Digite o e-mail!')];

const CollectionPointPassportAdminEdit = (props) =>
{
  return (
  <Edit {...props} title={ <EditTitle subtitle="Editar Admin de Ponto de Retirada de Passaporte: " value="name" /> }>
    <SimpleForm redirect="/collection-point-passport-admin" toolbar={<CustomEditToolbar />} >
      <ReferenceInput source="collection_point_passport_id" label="Ponto de Retirada" reference="setup/collection-points-passport" validate={validateCollectionPointPassport} fullWidth={true}>
        <SelectInput optionText="name" optionValue="id" />
      </ReferenceInput>
      <TextInput source="name" label="Nome" fullWidth={true} validate={validateName} />
      <TextInput source="email" label="E-mail de Contato" type="email" fullWidth={true} validate={validateEmail} />
      <TextInput source="password" label="Senha" fullWidth={true} />
      <BooleanInput source="is_master" label="Master" />
      <BooleanInput source="active" label="Ativo" />
    </SimpleForm>
  </Edit>
)};

export default CollectionPointPassportAdminEdit;
